import React, { useState, useEffect } from "react";

interface Props {
  content: string;
}

export const GeneratingCitations = ({ content }: Props) => {
  const [dotCount, setDotCount] = useState(0);
  const [direction, setDirection] = useState(1);

  // Update the number of dots every 500ms
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Only update dotCount if it's within bounds
      if (dotCount === 3) {
        setDirection(-1); // Start reducing the dots
      } else if (dotCount === 0) {
        setDirection(1); // Start increasing the dots
      }

      // Update dotCount based on direction
      setDotCount((prev) => {
        const newDotCount = prev + direction;
        // Ensure dotCount stays between 0 and 3
        return Math.max(0, Math.min(newDotCount, 3));
      });
    }, 500);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [dotCount, direction]);

  // Generate the string with dots based on the current count
  const dots = ".".repeat(dotCount);

  return (
    <div style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "10px" }}>
      {content}
      {dots}
    </div>
  );
};
