import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import "./documentDetails.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { uploadFileFolderService } from "../../../api";
import {
  formatDateToMMDDYYYY,
  openNotificationWithIcon,
  PAGE_URL,
  toPascalCaseWithSpace,
} from "../../../utils";
import { Loading, PdfViewer } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { CloseIconProviderContract } from "../../../assets/icons";
import { ProviderContractStreamContainer } from "../ProviderContractStreamContainer";

const getValueForKey = (obj: any, key: string) => {
  if (obj[key] === null || obj[key] === "null") return "NA";
  if (key.toLowerCase().includes("date")) return formatDateToMMDDYYYY(obj[key]);
  return obj[key];
};

export const DocumentDetailsContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");
  const { documentDetails } = useSelector((state: RootState) => state.providerContract);
  const navigate = useNavigate();
  const { projectId } = useParams<{ projectId: string }>();

  const getSignedUrlByPath = async () => {
    try {
      setIsLoading(true);
      const { data } = await uploadFileFolderService.getSignedUrlByUuid(documentDetails.documentId);
      setFileUrl(data.signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSignedUrlByPath();
  }, []);

  const renderMetaData = () => {
    return (
      <div style={{ overflow: "auto", height: "100% " }}>
        <h1>Analysis</h1>
        <div className="row">
          <div className="label">
            <b>Data Element</b>
          </div>
          <div className="value">
            <b>Value</b>
          </div>
        </div>
        {Object.keys(documentDetails.metadata).map((key: string, index: number) => {
          return (
            <div key={index} className="row">
              <div className="label">{toPascalCaseWithSpace(key)}</div>
              <div className="value">{getValueForKey(documentDetails.metadata, key)}</div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="file-details-container provider-contract-document-details h-full">
      <div style={{ flex: 1, flexBasis: "10vw", width: "50%" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <PdfViewer
            pdf={fileUrl}
            displayPagination
            setCurrPage={setCurrentPage}
            currPage={currentPage}
          />
        )}
      </div>
      <div style={{ position: "relative", flex: "1.5 1", height: "100%" }}>
        <CloseIconProviderContract
          className="close-icon-single-doc"
          onClick={() => {
            navigate(`${PAGE_URL.PROJECTS}/${projectId}${PAGE_URL.PROVIDER_CONTRACT}`);
          }}
        />
        <Tabs centered defaultActiveKey="1" tabBarGutter={10} className="tabView">
          <Tabs.TabPane tab={<div className="tabView-title">Analysis</div>}>
            {renderMetaData()}
          </Tabs.TabPane>
          <Tabs.TabPane tab={<div className="tabView-title">Ask Auto</div>} key="2">
            <ProviderContractStreamContainer />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};
