import React, { useMemo, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { Layout } from "antd";
import { PAGE_TITLE, PAGE_URL } from "../../../utils";
import {
  autonomizeFullLogo,
  autonomizeLogo,
  molinaFullLogo,
  molinaLogo,
} from "../../../assets/images";
import { CollapseIcon } from "../../../assets/icons";
import { getAppMenuItems } from "./AppMenuItems";
import "./app-menu.scss";
import { useAuthUser } from "../../../hooks";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

const { Sider } = Layout;

export const AppMenu = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const { roleCode } = useAuthUser();
  const { project } = useSelector((state: RootState) => state.fileFolderUpload);
  const { pageTitle } = useMemo(() => {
    const pathname = location.pathname;
    let title = PAGE_TITLE[0].title;
    PAGE_TITLE.forEach((page) => {
      if (pathname.includes(page.route)) {
        title = page.title;
      }
    });
    return { pageTitle: title };
  }, [location.pathname]);
  //checking if the url is same as the businessGroup.name so make the icon not clickable
  const url = decodeURI(location.pathname.replace("/business-group/", ""));
  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      className="sidebar"
      width={170}
      trigger={
        <div className="flex gp-10 aic">
          <CollapseIcon className={collapsed ? "rotate-180" : ""} />
        </div>
      }
    >
      <div className="logo">
        <Link to={PAGE_URL.DASHBOARD}>
          <img src={collapsed ? autonomizeLogo : autonomizeFullLogo} alt="Molina Logo" />
        </Link>
      </div>
      <ul className="sidebar-nav">
        {getAppMenuItems(roleCode, project?.uuid?.toLowerCase(), project?.type).map((item, i) => {
          return (
            <li key={`left-menu-item-${i}`}>
              <Link
                className={`sidebar-nav-link flex aic gp ${
                  item.link.startsWith(decodeURI(location.pathname.replace("/file-details", "")))
                    ? "active"
                    : ""
                }`}
                to={item.link}
                title={collapsed ? item.title : ""}
                onClick={(e) => {
                  if (url === project.name && item.title === "Datasets") e.preventDefault();
                }}
              >
                {item.icon}
                {!collapsed && <span className="menu-title">{item.title}</span>}
              </Link>
            </li>
          );
        })}
      </ul>
    </Sider>
  );
};
