import React, { useEffect, useMemo, useState } from "react";
import { ISources, uploadFileFolderService } from "../../../api";
import { downloadPDF, openNotificationWithIcon } from "../../../utils";
import { Loading } from "../../../components";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

interface Props {
  chatSource: ISources;
}

export const ProviderContractSource = ({ chatSource }: Props) => {
  const { documentUUID, pages } = chatSource;
  const [currentSourceContent, setCurrentSourceContent] = useState("");
  const [isSourceContentLaoding, setIsSourceContentLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState("");
  const [isTextFile, setIsTextFile] = useState(false);
  const [isDocFile, setIsDocFile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { projectId } = useParams<{ projectId: string }>();

  const highlightedSourceContent = useMemo(() => {
    if (currentSourceContent.length > 0) {
      let content = currentSourceContent;
      const terms = pages[0].offsets.map((offset) =>
        currentSourceContent.substring(offset.begin, offset.end)
      );
      terms.forEach((term) => {
        const html = `<span class="bg-yellow">${term}</span>`;
        content = content.replace(term, html);
      });
      content = content.replaceAll(`\n`, `<br/>`);
      return content;
    }
    return "";
  }, [currentSourceContent]);

  const fetchSourceContent = async (page: number) => {
    setIsSourceContentLoading(true);
    try {
      const { data } = await uploadFileFolderService.getProviderContractFileOCR(
        documentUUID,
        page,
        projectId ?? ""
      );
      setCurrentSourceContent(data.data.ocr_text);
    } catch (err: any) {
      openNotificationWithIcon("", "Error While Fetching Content!", "error");
    } finally {
      setIsSourceContentLoading(false);
    }
  };

  useEffect(() => {
    fetchSourceContent(pages[0].pageNo);
  }, []);

  useEffect(() => {
    const getSignedurl = async () => {
      setIsLoading(true);
      try {
        const { data } = await uploadFileFolderService.getSignedURLForFullSource(
          chatSource.documentUUID
        );
        const filepath = data.data.filePath;
        setIsTextFile(filepath.endsWith(".txt"));
        setIsDocFile(filepath.endsWith(".doc") || filepath.endsWith(".docx"));
        setFileUrl(data.data.signedUrl);
      } catch (err: any) {
        openNotificationWithIcon("", "Something went wrong!", "error");
      } finally {
        setIsLoading(false);
      }
    };
    getSignedurl();
  }, [documentUUID, isDocFile]);

  const handleFileDownload = async () => {
    const extension = isDocFile ? "docx" : isTextFile ? "txt" : "pdf";
    await downloadPDF(fileUrl, `source_${new Date().getTime()}.${extension}`);
  };

  return (
    <>
      <div className="m-b">
        <h5>Results in: </h5>
        <ul className="source-list grid grid-cols-3 gp-10">
          {pages.map((item, index) => (
            <li key={index}>{`Page ${item.pageNo}`}</li>
          ))}
        </ul>
      </div>
      <div className="source-content">
        {/* <h5>Source Content</h5> */}
        {isSourceContentLaoding ? (
          <Loading />
        ) : (
          <div dangerouslySetInnerHTML={{ __html: highlightedSourceContent }}></div>
        )}
        <Button
          className="outline download-button"
          onClick={handleFileDownload}
          icon={<DownloadOutlined />}
          style={{ width: "100px", marginBottom: 25 }}
        >
          Download
        </Button>
      </div>
    </>
  );
};
