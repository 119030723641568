import React from "react";
import {
  snowflakeEmblem,
  dropbox,
  googleDrive,
  databricks,
  box,
  dataSource,
  azureLogo,
  sharePoint,
  TickLogo,
  UiUpload,
} from "../../../assets/images";
import { Card } from "antd";
import { useDispatch } from "react-redux";
import { RootState, openModal } from "../../../store";
import { MODAL_TYPE } from "../../../utils";
import { useSelector } from "react-redux";

export const DatasetConnectToDataSource = () => {
  const dispatch = useDispatch();
  const { project,topicId } = useSelector((state: RootState) => state.fileFolderUpload);
  const disableDataSourceCards =
    process.env.REACT_APP_DISABLE_DATA_SOURCE_CARDS &&
    process.env.REACT_APP_DISABLE_DATA_SOURCE_CARDS === "true";

  const cardsEnabled = (project.storage==='azure' && !project.sourceName);

  const areStringsEqual = (str1: string, str2: string): boolean => {
     if (str1 === null || str2 === null) {
       return false;
     }
    const normalize = (s: any): string => s.toLowerCase().replace(/[^a-z0-9]/g, "");
    return normalize(str1) === normalize(str2);
  };

  return (
    <div className="dataset-connect">
      <Card
        className={`cursor-pointer hover-border ${
          project.storage === "azure" &&
          project?.sourceName &&
          !areStringsEqual(project.displayName, project.sourceName) &&
          !cardsEnabled
            ? "green-border"
            : cardsEnabled
            ? ""
            : "disabled-card"
        }`}
        cover={
          // <div className="cover-container">
          <>
            <img src={azureLogo} alt="Azure Logo" className="main-image" />
            {project.storage === "azure" &&
              project?.sourceName &&
              !areStringsEqual(project.displayName, project.sourceName) &&
              !cardsEnabled && (
                <div>
                  <img src={TickLogo} alt="Small Image" className="small-image" />
                </div>
              )}
          </>
        }
        onClick={() => {
          if (cardsEnabled) {
            dispatch(
              openModal({
                modalName: MODAL_TYPE.CONNECT_TO_DATA_SOURCE,
                dataSource: "azure",
              })
            );
          }
        }}
      ></Card>
      <Card
        className={`cursor-pointer hover-border ${
          project.storage === "sharepoint" && !cardsEnabled
            ? "green-border"
            : cardsEnabled
            ? ""
            : "disabled-card"
        }`}
        cover={
          // <div className="cover-container">
          <>
            <img src={sharePoint} className="main-image" />
            {project.storage === "sharepoint" && !cardsEnabled && (
              <div>
                <img src={TickLogo} alt="Small Image" className="small-image" />
              </div>
            )}
          </>
        }
        onClick={() => {
          if (cardsEnabled) {
            dispatch(
              openModal({
                modalName: MODAL_TYPE.CONNECT_TO_DATA_SOURCE,
                dataSource: "sharepoint",
              })
            );
          }
        }}
      ></Card>
      <Card
        className={disableDataSourceCards ? "disabled-card hover-border" : "hover-border"}
        cover={<img src={snowflakeEmblem} className="main-image" />}
      ></Card>
      <Card
        className={disableDataSourceCards ? "disabled-card hover-border" : "hover-border"}
        cover={<img src={dropbox} className="main-image" />}
      ></Card>
      <Card
        className={disableDataSourceCards ? "disabled-card hover-border" : "hover-border"}
        cover={<img src={googleDrive} className="main-image" />}
      ></Card>
      <Card
        className={disableDataSourceCards ? "disabled-card hover-border" : "hover-border"}
        cover={<img src={databricks} className="main-image" />}
      ></Card>
      <Card
        className={disableDataSourceCards ? "disabled-card hover-border" : "hover-border"}
        cover={<img src={box} className="main-image" />}
      ></Card>
      <Card
        className={`cursor-pointer hover-border ${
          project?.sourceName &&
          areStringsEqual(project.displayName, project.sourceName) &&
          !cardsEnabled
            ? "green-border"
            : cardsEnabled
            ? ""
            : "disabled-card"
        }`}
        cover={
          // <div className="cover-container">
          <>
            <img src={UiUpload} className="main-image" />
            {project?.sourceName &&
              areStringsEqual(project.displayName, project.sourceName) &&
              !cardsEnabled && (
                <div>
                  <img src={TickLogo} alt="Small Image" className="small-image" />
                </div>
              )}
          </>
        }
        onClick={() => {
          if (cardsEnabled) {
            dispatch(
              openModal({
                modalName: MODAL_TYPE.SOURCE_CONFIRMATION,
                dataSource: "azure",
              })
            );
          }
        }}
      ></Card>
      <Card
        className={disableDataSourceCards ? "disabled-card hover-border" : "hover-border"}
        cover={<img src={dataSource} className="main-image" />}
      ></Card>
    </div>
  );
};
