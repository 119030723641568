import React from "react";
import { Route, Routes } from "react-router-dom";
import * as Pages from "../pages";

export const ProviderContractRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.ProviderContract />} />
      <Route path=":documentUuid" element={<Pages.DocumentDetails />} />
    </Routes>
  );
};
