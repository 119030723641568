import axios, { AxiosRequestConfig } from "axios";
import { store } from "../App";
import { logoutUser } from "../store";
import { PAGE_URL } from "../utils";
import Config from "../config.json";

declare global {
  interface Window {
    _env_: {
      REACT_APP_API_BASE_URL: string;
      [key: string]: any; // for any other properties you might have on _env_
    };
  }
}

export const authHeaders = (headers: any, token: string): any => {
  const namespace = "authorization";
  const privateNamespace = "x-user-token";
  headers[namespace] = `Bearer ${token}`;
  headers[privateNamespace] = "true";
  return headers;
};

export const withAuthHeaders = (headers: any): any => {
  const token = store.getState().authUser.user.accessToken;
  return authHeaders(headers, token || "");
};

const defaultOptions = {
  // baseURL: process.env.REACT_APP_API_BASE_URL,
  baseURL: window._env_.REACT_APP_API_BASE_URL, //change to process.env while running in local, do not push
  headers: {
    "Content-Type": "application/json",
  },
};

const genesisOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};

const activeDirectoryOptions = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
};
const apiClient = axios.create(defaultOptions);
const genesisApiClient = axios.create(genesisOptions);
const activeDirectoryApiClient = axios.create(activeDirectoryOptions);

apiClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    //   set user token here
    config.headers = withAuthHeaders({});
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (res) => res,
  (error: any) => {
    // handle user session refresh here
    const pathname = window.location.pathname;
    if (pathname !== PAGE_URL.LOGIN && error.response.status === 401) {
      store.dispatch(logoutUser());
      window.location.replace(PAGE_URL.LOGIN);
    }
    if (error.response.status >= 500) {
      error.response.message = "Something went wrong!";
    }

    if (error.response.status !== 401) {
      error.response.message = "";
    }
    return Promise.reject(error);
  }
);

export { apiClient, genesisApiClient, activeDirectoryApiClient };
