import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { Loading, PdfViewer, withAuthorization } from "../../../components";
import { ChatAside } from "../../chat";
import { ISources } from "../../../api";
import { CloseOutlined } from "@ant-design/icons";
import { uploadFileFolderService } from "../../../api";
import { STATUS_CODE, openNotificationWithIcon } from "../../../utils";
import "./sourceModal.scss";
import { usePagination } from "../../../hooks";
import { ChatSource, ProviderContractSource } from "../../chat/chat-aside";
import { useLocation } from "react-router-dom";

interface SourceModalProps {
  open: boolean;
  onClose: () => void;
  source: ISources;
  page?: number; // Optional page prop
}

const AuthorizedChatSource = withAuthorization(ChatSource);

const SourceModal: React.FC<SourceModalProps> = ({ open, onClose, source, page }) => {
  const { onPaginationChange, page: currentPage, pageSize } = usePagination();
  const [currPage, setCurrPage] = useState<number | undefined>(page); // Use page prop or currentPage
  const [expanded, setExpanded] = useState(true);
  const [fileUrl, setFileUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthorized, setIsAuthorized] = useState(true);
  const location = useLocation();

  const isProviderContract = location.pathname.includes("provider-contract");

  useEffect(() => {
    const getSignedUrl = async () => {
      setIsLoading(true);
      try {
        if (open) {
          const { data } = await uploadFileFolderService.getSignedUrlByUuid(source.documentUUID);
          setFileUrl(data.signedUrl);
        }
      } catch (err: any) {
        if (err.response?.status === STATUS_CODE.FORBIDDEN) {
          setIsAuthorized(false);
        } else {
          // openNotificationWithIcon("", err.response?.data?.message, "error");
          onClose();
        }
      } finally {
        setIsLoading(false);
      }
    };

    getSignedUrl();
    const initialPage = page ?? source.pages[0]?.pageNo ?? 1; // Use page prop if available, otherwise default to first page
    setCurrPage(initialPage);
    onPaginationChange(initialPage, pageSize);

    return () => {
      onPaginationChange(1, pageSize);
    };
  }, [open, page]);

  return (
    <Modal
      className="source-modal autonomizeModal"
      open={open}
      closeIcon={<CloseOutlined onClick={onClose} />}
      width={1000}
      footer={null}
    >
      <div className="source-modal title">
        <h1>{source.fileName}</h1>
      </div>
      <div className="source-modal content">
        <div className="source-modal pdf-viewer">
          {isLoading ? (
            <Loading />
          ) : (
            <PdfViewer
              pdf={fileUrl}
              currPage={currPage || 1}
              setCurrPage={(pages) => setCurrPage(pages)}
              displayPagination={true}
            />
          )}
        </div>
        <div className="source-modal chat-aside">
          {isProviderContract ? (
            <ProviderContractSource chatSource={source} />
          ) : (
            <AuthorizedChatSource
              setHideChatHistory={() => {}}
              chatSource={source}
              expanded={expanded}
              setExpanded={setExpanded}
              setCurrPage={(pages) => setCurrPage(pages)}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default SourceModal;
