import React from "react";
import { IconsProps } from "./Icons";

export const CloseIconProviderContract = ({
  className,
  fill,
  width,
  height,
  viewBox,
  onClick,
}: IconsProps) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <rect width="18" height="18" rx="9" fill="#D9D9D9" />
      <path d="M13 13L5 5M13 5L5 13" stroke="#475569" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};
