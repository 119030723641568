import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IQuesAns {
  query: string;
  ans: any;
  showQuery: boolean;
}

interface QnAPayload {
  projectId: string;
  queryAns: IQuesAns;
}

interface IProjectQueryAns {
  projectId: string;
  queryAnsList: IQuesAns[];
}

interface IProviderContract {
  documentDetails: { [key: string]: any };
  multiDocQueryAnsList: IProjectQueryAns[];
}

export const providerContractInitialState: IProviderContract = {
  documentDetails: {},
  multiDocQueryAnsList: [],
};

export const providerContractSlice = createSlice({
  name: "providerContract",
  initialState: providerContractInitialState,
  reducers: {
    setProjectDetails: (
      state: IProviderContract,
      { payload }: PayloadAction<{ [key: string]: any }>
    ) => {
      state.documentDetails = payload;
    },
    setMultiDocQueryAnsList: (state: IProviderContract, { payload }: PayloadAction<QnAPayload>) => {
      const idx = state.multiDocQueryAnsList.findIndex((x) => x.projectId === payload.projectId);
      if (idx !== -1) {
        state.multiDocQueryAnsList[idx].queryAnsList.push(payload.queryAns);
      } else {
        state.multiDocQueryAnsList.push({
          projectId: payload.projectId,
          queryAnsList: [payload.queryAns],
        });
      }
    },
  },
});

export const { setProjectDetails, setMultiDocQueryAnsList } = providerContractSlice.actions;
export default providerContractSlice.reducer;
