import React from "react";
import { PAGE_URL, PROJECT_TYPE } from "../../../utils";
import { ChatIcon, HomeIcon, DataIcon, SettingsIcon } from "../../../assets/icons";

// const ADMIN_LEFT_MENU_ITEMS: ILeftMenuItem[] = [
//   {
//     link: PAGE_URL.DASHBOARD,
//     icon: <HomeIcon />,
//     title: "Dashboard",
//   },
// ];

const getRoute = (projectUUID: string, projectType: string) => {
  if (projectType === PROJECT_TYPE.MULTI_DOC_QA)
    return `${PAGE_URL.PROJECTS}/${projectUUID}${PAGE_URL.ASK_AUTO}`;
  if (projectType === PROJECT_TYPE.SINGLE_DOC_QA)
    return `${PAGE_URL.PROJECTS}/${projectUUID}${PAGE_URL.DOCUMENTS}`;
  return `${PAGE_URL.PROJECTS}/${projectUUID}${PAGE_URL.PROVIDER_CONTRACT}`;
};

const USER_LEFT_MENU_ITEMS_PROJECTS = (
  projectUUID: string,
  projectType: string
): ILeftMenuItem[] => {
  return [
    {
      link: PAGE_URL.DASHBOARD,
      icon: <HomeIcon />,
      title: "Projects",
    },
    {
      link: getRoute(projectUUID, projectType),
      icon: <ChatIcon className="chat-icon" />,
      title: "Ask Auto",
    },
    {
      link: `${PAGE_URL.PROJECTS}/${projectUUID}${PAGE_URL.DATASETS}`,
      icon: <DataIcon />,
      title: "Datasets",
    },
    {
      link: `${PAGE_URL.PROJECTS}/${projectUUID}${PAGE_URL.SETTINGS}`,
      icon: <SettingsIcon />,
      title: "Settings",
    },
  ];
};

const USER_LEFT_MENU_ITEMS_DASHBOARD: ILeftMenuItem[] = [
  {
    link: PAGE_URL.DASHBOARD,
    icon: <HomeIcon />,
    title: "Projects",
  },
];

export const getAppMenuItems = (roleCode: string, projectUUID: string, projectType: string) => {
  // const menu = [] as ILeftMenuItem[];
  // switch (roleCode) {
  //   case USER_ROLES_CODE.ADMIN:
  //     menu.push(...ADMIN_LEFT_MENU_ITEMS);
  //     break;
  //   case USER_ROLES_CODE.BUSINESS_USER:
  //     menu.push(...USER_LEFT_MENU_ITEMS);
  //     break;
  //   case USER_ROLES_CODE.DATA_SCIENTIST:
  //     menu.push(...USER_LEFT_MENU_ITEMS);
  //     break;
  // }
  // return menu;

  if (projectUUID) {
    return USER_LEFT_MENU_ITEMS_PROJECTS(projectUUID, projectType);
  } else {
    return USER_LEFT_MENU_ITEMS_DASHBOARD;
  }
};
