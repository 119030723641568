import React, { useState } from "react";
import { PageWrapper } from "../containers";
import { ProviderContractStreamContainer } from "../containers/provider-contract";
import { HistoryContainer } from "../containers/provider-contract/history-container/HistoryContainer";

const ProviderContract = () => {
  // const [showHistory, setShowHistory] = useState(true);
  // const [historyId, setHistoryId] = useState("");
  return (
    <PageWrapper
      // pageLeftAsideChildren={showHistory ? <HistoryContainer setHistoryId={setHistoryId} /> : <></>}
      pageContainerChildren={<ProviderContractStreamContainer />}
    />
  );
};

export default ProviderContract;
