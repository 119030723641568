export const getCurrentDateTime = () =>
  new Date().toLocaleDateString() + ", " + new Date().toLocaleTimeString();

export const getDate = (date: Date) => new Date(date).toISOString().split("T")[0];

export const getTime = (date: Date) =>
  `${
    date
      .toLocaleDateString("en-us", {
        hour12: true,
        hour: "numeric",
        minute: "numeric",
      })
      .split(",")[1]
  }`;

export const getLocalDateTimeStringFromISO = (datetime: string) => {
  if (!datetime) return "-";
  const date = new Date(datetime);
  return `${getDate(date)} ${getTime(date)}`;
};

export const differenceInDays = (date1: Date, date2: Date): number => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const formatDate = (date: Date, pattern: string): string => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const monthLong = date.toLocaleString("default", { month: "long" });

  return pattern.replace("yyyy", year.toString()).replace("MMMM", monthLong).replace("MM", month);
};

export const isSameMonth = (date1: Date, date2: Date): boolean => {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
};

export const isSameYear = (date1: Date, date2: Date): boolean => {
  return date1.getFullYear() === date2.getFullYear();
};

export const formatDateToMMDDYYYY = (isoDate: string): string => {
  const date = new Date(isoDate);

  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};
