import React, { useEffect, useState } from "react";
import { AddContainerProps } from ".";
import { Button, Input, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { InformationIcon } from "../../../assets/icons";
import { TopicsService } from "../../../api/topic";
import { openNotificationWithIcon, sanitizeAndConvertToKebabCase } from "../../../utils";
import { Dropdown } from "../../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface IProjectDetails {
  projectName: string;
  folderName: string;
}

interface IDropdownOption {
  id: number;
  value: string;
}

interface IFolder {
  createdAt: string;
  isFolder: boolean;
  name: string;
  storage: string;
  updatedAt: string;
}

export const AddProject = ({
  handleClose,
  visibility,
  handleCanFetchContainers,
}: AddContainerProps) => {
  const [topicName, setTopicName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [containerName, setContainerName] = useState<string>("");
  const [isDropdownsDataLoading, setIsDropdownsDataLoading] = useState<boolean>(false);
  const fileFolderUpload = useSelector((state: RootState) => state.fileFolderUpload);
  const [folders, setFolders] = useState<IDropdownOption[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  const [projectDetails, setProjectDetails] = useState<IProjectDetails>({
    projectName: fileFolderUpload.project.name,
    folderName: "",
  });

  const fetchFolders = async () => {
    try {
      setIsDropdownsDataLoading(true);
      const { data } = await TopicsService.getFoldersByProjectId(fileFolderUpload.project.uuid);
      if (data.length === 0) {
        setShowError(true);
      } else {
        setShowError(false);
      }
      const folderOptions = data.map((folder: IFolder, index: number) => ({
        value: folder.name,
        id: index,
      }));
      setFolders(folderOptions);
    } catch (error) {
      openNotificationWithIcon("", "Error fetching folders", "error");
    } finally {
      setIsDropdownsDataLoading(false);
    }
  };

  useEffect(() => {
    if (topicName) {
      setContainerName(sanitizeAndConvertToKebabCase(topicName, true));
    }
  }, [topicName]);

  useEffect(() => {
    fetchFolders();
  }, []);

  const CheckRepeatingTopicName = async (projectId: string): Promise<boolean> => {
    try {
      const { data } = await TopicsService.getTopics(projectId);
      return data.data.some((topic: ITopic) => topic.displayName === topicName);
    } catch (error) {
      console.error("Error fetching topics:", error);
      return false;
    }
  };

  const createContainerButtonClick = async () => {
    setIsLoading(true);
    try {
      const isDuplicateName = await CheckRepeatingTopicName(fileFolderUpload.project.uuid);
      if (fileFolderUpload.project.uuid && !isDuplicateName) {
        await TopicsService.createNonDefaultTopics(
          topicName,
          fileFolderUpload.project.uuid,
          projectDetails.folderName
        );
        openNotificationWithIcon("", "Topic created successfully", "success");
        handleCanFetchContainers();
        handleClose();
      } else {
        openNotificationWithIcon("", "Topic with same name already exist", "error");
      }
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFoldersChange = (value: string) => {
    setProjectDetails((prevDetails) => ({
      ...prevDetails,
      folderName: value,
    }));
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      open={visibility}
      footer={null}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="addClient-title no-bd-bt">
        <h1>{"Create Topic"}</h1>
      </div>
      {showError && (
        <div className="flex jcc">
          <p style={{ color: "red", marginTop: "5px" }}>
            * Topic cannot be created as there are no folders
          </p>
        </div>
      )}
      <div className="addClient-content modal-form-content">
        <div className="single-content">
          <p className="font-Semibold">Topic Name : </p>
          <Input value={topicName} onChange={(e) => setTopicName(e.target.value)} />
        </div>
        <div>
          <p className="font-Semibold">Folders:</p>
          <Dropdown
            value={projectDetails.folderName}
            loading={isDropdownsDataLoading}
            options={folders}
            onSelect={handleFoldersChange}
            disabled={showError}
          />
        </div>
        <div className="single-content m-t flex aic gp-10 jcsb">
          <div className="flex aic gp-10">
            <InformationIcon className="link-color" />
            <p>Please adjust other settings after creating the project</p>
          </div>
          <Button
            className="fill"
            onClick={createContainerButtonClick}
            disabled={topicName === "" || projectDetails.folderName === ""}
            loading={isLoading}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};
