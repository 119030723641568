import React, { useEffect, useRef, useState } from "react";
import "./providerContract.scss";
import { Button, Flex, Tooltip } from "antd";
import Search from "antd/es/input/Search";
import { Loading } from "../../components";
import {
  CopyOutlined,
  DownOutlined,
  RedoOutlined,
  SendOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { BrandLogo } from "../../assets/images";
import { IQueryPostData, ProviderContractService } from "../../api/providerContract";
import { useNavigate, useParams } from "react-router-dom";
import { ThumbsDown, ThumbsUp } from "../../assets/icons";
import { ISources, SettingsService } from "../../api";
import { openNotificationWithIcon, PAGE_URL } from "../../utils";
import Markdown from "markdown-to-jsx";
import { GeneratingCitations } from "./components/AnimatingComponent";
import { Table } from "antd/lib";
import { useDispatch } from "react-redux";
import { setProjectDetails } from "../../store/provider-contract/slice";
import SourceModal from "../modals/source/SourceModal";

interface IQuesAns {
  query: string;
  ans: any[];
  showQuery: boolean;
}

const STREAMING_EVENTS = {
  THREAD_GENERATION: "THREADED_GENERATION",
  CITATION_GENERATION: "CITATION_GENERATION",
  ANSWER_CONSOLIDATION: "ANSWER_CONSOLIDATION",
  SEARCH_GENERATION: "SEARCH_GENERATION",
  ANSWER_GENERATION: "ANSWER_GENERATION",
};

const INTENT = {
  METADATA_SEARCH: "metadata_search",
  CONTRACT_COMPARISON: "contract_comparison",
  CHITCHAT: "chitchat",
};

const isMultiDocQa = (documentId?: string) => {
  if (!documentId) {
    return true;
  }
  return false;
};

// interface Props {
//   historyId?: string;
// }

export const ProviderContractStreamContainer = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isInputAndSearchDisabled, setIsInputAndSearchDisabled] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [isComparing, setIsComparing] = useState(false);
  const [isCompareDisabled, setIsCompareDisabled] = useState(false);
  const [showCompareButton, setShowCompareButton] = useState(false);
  const [selectedSource, setSelectedSource] = useState<ISources>({} as ISources);
  const [selectedRecords, setSelectedRecords] = useState<any[]>([]);
  const [inputVal, setInputVal] = useState("");
  const [compareQuery, setCompareQuery] = useState("");
  const [queryResponseList, setQueryResponseList] = useState<IQuesAns[]>([]);
  const [promptsList, setPromptsList] = useState<any[]>([]);
  const { projectId } = useParams<{ projectId: string }>();
  const { documentUuid } = useParams<{ documentUuid: string }>();
  const questionsCountRef = useRef<number>(0);
  const updatedQueryResponseList = useRef<IQuesAns[]>([]);
  const sourcesListRef = useRef<any[]>([]);
  const historyIdRef = useRef<string>("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const uuidRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;

  const fetchPrompts = async () => {
    try {
      const res = await SettingsService.getQAConfiguration(projectId);
      const prompts = res.data.data.projectPrompts
        ? res.data.data.projectPrompts.filter((x) => x.question.length !== 0)
        : [];
      setPromptsList(prompts);
    } catch (error) {
      openNotificationWithIcon("", "Error While Fetching Prompts!", "error");
    }
  };

  // const fetchHistoryByHistoryId = async () => {
  //   setIsInputAndSearchDisabled(true);
  //   setIsProcessing(true);
  //   setShowPrompts(false);
  //   try {
  //     const res = await ProviderContractService.getChatHistoryByHistoryId(
  //       projectId ?? "",
  //       historyId ?? ""
  //     );
  //     for (let i = 0; i < res.content.length; ) {
  //       updatedQueryResponseList.current.push({
  //         query: res.content[i].content,
  //         ans: [res.content[i + 1].content],
  //         showQuery: true,
  //       });
  //       i += 2;
  //     }
  //     questionsCountRef.current = updatedQueryResponseList.current.length;
  //     setQueryResponseList([...updatedQueryResponseList.current]);
  //   } catch (error) {
  //     openNotificationWithIcon("", "Error While Fetching History!", "error");
  //   } finally {
  //     setIsInputAndSearchDisabled(false);
  //     setIsProcessing(false);
  //   }
  // };

  const handleClick = (event: Event) => {
    const target = event.target as HTMLElement;
    const chunkId = target.getAttribute("data-chunk-id");
    if (chunkId) {
      setShowModal(true);
      const requiredObj = sourcesListRef.current.find((x) => x.pages[0].chunkUUID === chunkId);
      if (requiredObj) {
        setSelectedSource(requiredObj);
      }
    }
  };

  useEffect(() => {
    if (isMultiDocQa(documentUuid)) fetchPrompts();
  }, []);

  useEffect(() => {
    const spanElements = document.querySelectorAll(`[data-chunk-id]`);
    spanElements.forEach((ele) => {
      ele.addEventListener("click", (e) => handleClick(e));
    });
  }, [queryResponseList]);

  // useEffect(() => {
  //   if (historyId && historyId.length !== 0) {
  //     fetchHistoryByHistoryId();
  //   }
  // }, [historyId]);

  const getProcessedMarkdown = (markdown: string, idx: number) => {
    const uuidIndexMap = new Map(); // To map UUIDs to their corresponding indices
    let index = 0; // To keep track of the incrementing number

    return markdown.replace(uuidRegex, (uuid) => {
      // If the UUID has already been seen, retrieve its index
      if (uuidIndexMap.has(uuid)) {
        return `<span style="cursor: pointer; color: #3232a6; font-weight: bold;" data-chunk-id="${uuid}" data-source-idx="${idx}">${uuidIndexMap.get(
          uuid
        )}</span>`;
      }
      index++; // Increment for a new UUID
      uuidIndexMap.set(uuid, index); // Store the index for the new UUID
      return `<span style="cursor: pointer; color: #3232a6; font-weight: bold;" data-chunk-id="${uuid}" data-source-idx="${idx}">${index}</span>`;
    });
  };

  const handleMetaDataSearchIntent = async (parsedObj: any) => {
    let noDataString = "";
    if (parsedObj.event.generating) {
      if (updatedQueryResponseList.current.length < questionsCountRef.current) {
        updatedQueryResponseList.current.push({
          query: compareQuery.length === 0 ? inputVal : compareQuery,
          ans: [<GeneratingCitations content={"Generating Response"} key="response" />],
          showQuery: compareQuery.length !== 0 ? false : true,
        });
        setQueryResponseList([...updatedQueryResponseList.current]);
      }
      noDataString += parsedObj.response;
    } else if (parsedObj.event.generating === false) {
      const requiredObj = updatedQueryResponseList.current[questionsCountRef.current - 1];
      if (parsedObj.results.length === 0) {
        requiredObj.ans = [noDataString];
      } else {
        requiredObj.ans = [parsedObj.results];
      }
      setQueryResponseList([...updatedQueryResponseList.current]);
    }
  };

  const handleContractComparisonIntent = async (
    parsedObj: any,
    responseMapping: Record<string, any>
  ) => {
    const eventName = parsedObj.event.current;
    const threadId = parsedObj.event.thread_id;
    const responseString = parsedObj.response;
    const requiredObj = updatedQueryResponseList.current[questionsCountRef.current - 1];
    switch (eventName) {
      case STREAMING_EVENTS.THREAD_GENERATION:
        if (threadId in responseMapping) {
          const prevString = responseMapping[threadId];
          const newString = prevString + responseString;
          responseMapping[threadId] = newString;
        } else if (threadId !== -1) {
          responseMapping[threadId] = responseString;
        }
        updatedQueryResponseList.current.length < questionsCountRef.current
          ? updatedQueryResponseList.current.push({
              query: compareQuery.length === 0 ? inputVal : compareQuery,
              ans: [responseMapping],
              showQuery: compareQuery.length !== 0 ? false : true,
            })
          : (updatedQueryResponseList.current[questionsCountRef.current - 1].ans = [
              responseMapping,
            ]);
        break;
      case STREAMING_EVENTS.ANSWER_CONSOLIDATION:
        if (parsedObj.event.generating) {
          if (requiredObj.ans.length == 1) {
            requiredObj.ans.push(responseString);
          } else {
            const prevString = requiredObj.ans[1];
            const newString = prevString + responseString;
            requiredObj.ans[1] = newString;
          }
        } else if (parsedObj.event.next === "CITATION_GENERATION") {
          requiredObj.ans.push(<GeneratingCitations content={"Generating Citations..."} />);
        }
        break;
      case STREAMING_EVENTS.CITATION_GENERATION:
        sourcesListRef.current = [
          ...sourcesListRef.current,
          ...parsedObj.sources.map((source: any) => {
            return {
              fileName: source.file_name,
              documentUUID: source.document_id,
              pages: [
                {
                  pageNo: source.page_number,
                  chunkUUID: source.id,
                  offsets: [{ begin: source.begin_offset, end: source.end_offset }],
                },
              ],
            };
          }),
        ];
        requiredObj.ans.pop();
        requiredObj.ans[1] = getProcessedMarkdown(responseString, questionsCountRef.current - 1);
        break;
    }
    setQueryResponseList([...updatedQueryResponseList.current]);
  };

  const handleChitChatIntent = async (parsedObj: any) => {
    const responseString = parsedObj.response;
    if (updatedQueryResponseList.current.length < questionsCountRef.current) {
      updatedQueryResponseList.current.push({
        query: compareQuery.length === 0 ? inputVal : compareQuery,
        ans: [responseString],
        showQuery: compareQuery.length !== 0 ? false : true,
      });
    } else {
      const requiredObj = updatedQueryResponseList.current[questionsCountRef.current - 1];
      const prevString = requiredObj.ans[0];
      const newString = prevString + responseString;
      requiredObj.ans[0] = newString;
    }
    setQueryResponseList([...updatedQueryResponseList.current]);
  };

  const handleSingleDocIntent = async (parsedObj: any) => {
    const eventName = parsedObj.event.current;
    const responseString = parsedObj.response;
    const requiredObj = updatedQueryResponseList.current[questionsCountRef.current - 1];
    switch (eventName) {
      case STREAMING_EVENTS.ANSWER_GENERATION:
        if (parsedObj.event.generating) {
          if (updatedQueryResponseList.current.length < questionsCountRef.current) {
            updatedQueryResponseList.current.push({
              query: compareQuery.length === 0 ? inputVal : compareQuery,
              ans: [responseString],
              showQuery: compareQuery.length !== 0 ? false : true,
            });
          } else {
            const prevString = requiredObj.ans[0];
            const newString = prevString + responseString;
            requiredObj.ans[0] = newString;
          }
        } else if (parsedObj.event.next === "CITATION_GENERATION") {
          requiredObj.ans.push(<GeneratingCitations content={"Generating Citations..."} />);
        }
        break;
      case STREAMING_EVENTS.CITATION_GENERATION:
        sourcesListRef.current = [
          ...sourcesListRef.current,
          ...parsedObj.sources.map((source: any) => {
            return {
              fileName: source.file_name,
              documentUUID: source.document_id,
              pages: [
                {
                  pageNo: source.page_number,
                  chunkUUID: source.id,
                  offsets: [{ begin: source.begin_offset, end: source.end_offset }],
                },
              ],
            };
          }),
        ];
        requiredObj.ans.pop();
        requiredObj.ans[0] = getProcessedMarkdown(responseString, questionsCountRef.current - 1);
        break;
    }
    setQueryResponseList([...updatedQueryResponseList.current]);
  };

  const handleQueryEvent = async () => {
    if (inputVal.trim().length === 0) return;
    setIsInputAndSearchDisabled(true);
    setIsProcessing(true);
    setShowPrompts(false);
    questionsCountRef.current += 1;
    const postData: IQueryPostData = {
      query: inputVal,
      projectId: projectId ?? "",
    };
    if (documentUuid) {
      postData.documentId = documentUuid;
    }
    if (historyIdRef.current.length !== 0) {
      postData.historyId = historyIdRef.current;
    }
    try {
      const eventSource = ProviderContractService.getEventSourceInstance(postData);
      const responseMapping: Record<number, any> = {};
      eventSource.onmessage = (event) => {
        const requiredString = event.data;
        const parsedObj = JSON.parse(requiredString);
        const intent = parsedObj.intent;
        const requiredHistoryId = parsedObj.history_id;
        if (historyIdRef.current.length === 0 && requiredHistoryId) {
          historyIdRef.current = requiredHistoryId;
        }
        switch (intent) {
          case INTENT.METADATA_SEARCH:
            handleMetaDataSearchIntent(parsedObj);
            break;
          case INTENT.CONTRACT_COMPARISON:
            handleContractComparisonIntent(parsedObj, responseMapping);
            break;
          case INTENT.CHITCHAT:
            handleChitChatIntent(parsedObj);
            break;
          default:
            handleSingleDocIntent(parsedObj);
            break;
        }
      };
      eventSource.onerror = () => {
        setIsInputAndSearchDisabled(false);
        setIsProcessing(false);
        eventSource.close();
        setInputVal("");
      };
    } catch (error) {
      console.log(error);
    }
  };

  const rowSelection = {
    onChange: (_: any, selectedRows: any[]) => {
      if (selectedRows.length >= 2) {
        setShowCompareButton(true);
      } else {
        setShowCompareButton(false);
      }
      setSelectedRecords([...selectedRows]);
    },
  };

  const handleCompareEvent = async () => {
    const documentIds = selectedRecords.map((record: any) => record.documentId);
    const query = `Compare contracts of Document Id ${documentIds.join(" and ")} .`;
    setIsComparing(true);
    setIsCompareDisabled(true);
    setCompareQuery(query);
    setIsInputAndSearchDisabled(true);
    questionsCountRef.current++;
    const postData: IQueryPostData = {
      query,
      projectId: projectId ?? "",
    };
    if (historyIdRef.current.length !== 0) {
      postData.historyId = historyIdRef.current;
    }
    try {
      const eventSource = ProviderContractService.getEventSourceInstance(postData);
      const responseMapping: Record<number, any> = {};
      eventSource.onmessage = (event) => {
        const requiredString = event.data;
        const parsedObj = JSON.parse(requiredString);
        const intent = parsedObj.intent;
        switch (intent) {
          case INTENT.METADATA_SEARCH:
            handleMetaDataSearchIntent(parsedObj);
            break;
          case INTENT.CONTRACT_COMPARISON:
            handleContractComparisonIntent(parsedObj, responseMapping);
            break;
          case INTENT.CHITCHAT:
            handleChitChatIntent(parsedObj);
            break;
          default:
            handleSingleDocIntent(parsedObj);
            break;
        }
      };
      eventSource.onerror = () => {
        setIsComparing(false);
        setIsCompareDisabled(false);
        setCompareQuery("");
        setIsInputAndSearchDisabled(false);
      };
    } catch (error) {
      console.log("Error While Comparing");
    }
  };

  const renderQueryAndResponse = (ans: any[]) => {
    return ans.map((item, idx) => {
      if (typeof item === "string") {
        return (
          <Markdown key={idx} className={"reactMarkDown"}>
            {item}
          </Markdown>
        );
      }
      if (Array.isArray(item)) {
        const requiredList = item.map((ele, index) => {
          return {
            documentId: (ele.document_id || ele.metadata.document_id) ?? "",
            fileName: (ele.file_name || ele.metadata.file_name) ?? "",
            providerName: (ele.provider_name || ele.metadata.provider_name) ?? "",
            effectiveDate: (ele.effective_date || ele.metadata.effective_date) ?? "",
            state: (ele.state || ele.metadata.state) ?? "",
            metadata: ele.metadata,
            key: index,
          };
        });
        const columns = [
          {
            title: "File Name",
            dataIndex: "fileName",
            key: "fileName",
            render: (text: string, record: any) => (
              <span
                style={{ color: "#3232a6", cursor: "pointer" }}
                onClick={() => {
                  dispatch(setProjectDetails(record));
                  navigate(
                    `${PAGE_URL.PROJECTS}/${projectId}${PAGE_URL.PROVIDER_CONTRACT}/${record.documentId}`
                  );
                }}
              >
                {text}
              </span>
            ),
          },
          {
            title: "Provider Name",
            dataIndex: "providerName",
            key: "providerName",
            render: (text: string) => <span>{text}</span>,
          },
          {
            title: "Effective Date",
            dataIndex: "effectiveDate",
            key: "effectiveDate",
            render: (text: string) => {
              const date = new Date(text);
              const options: Intl.DateTimeFormatOptions = {
                year: "numeric",
                month: "long",
                day: "2-digit",
              };
              const formattedDate: string = date.toLocaleDateString("en-US", options);
              return formattedDate;
            },
          },
          {
            title: "State",
            dataIndex: "state",
            key: "state",
            render: (text: string) => <span>{text}</span>,
          },
        ];
        return (
          <>
            <Table
              dataSource={requiredList}
              columns={columns}
              pagination={false}
              key={idx}
              rowSelection={rowSelection}
            />
            {showCompareButton && (
              <Button
                type="primary"
                style={{ alignSelf: "flex-start", background: "#3232a6" }}
                disabled={isCompareDisabled}
                loading={isComparing}
                onClick={handleCompareEvent}
                className="mt-20"
              >
                Compare
              </Button>
            )}
          </>
        );
      }
      if (React.isValidElement(item)) return item;
      const sortedThreadIds = Object.keys(item)
        .map(Number)
        .sort((a, b) => a - b);
      return (
        <div className="evidence-grid" key={idx}>
          {sortedThreadIds.map((threadId) => {
            const responses = item[threadId];
            return (
              <div key={threadId} className="evidence-item">
                <div className="evidence-heading">
                  <strong>Evidence {threadId + 1}</strong>
                </div>
                <div className="evidence-content">
                  {/* Render each response as markdown */}
                  <Markdown className={"reactMarkDown"}>{responses}</Markdown>
                </div>
              </div>
            );
          })}
        </div>
      );
    });
  };

  const renderLogoAndDescription = () => {
    if (queryResponseList.length === 0)
      return (
        <Flex
          gap={20}
          vertical
          align="center"
          style={{ padding: "20px 35px", cursor: "pointer", flex: "1", justifyContent: "center" }}
        >
          <img src={BrandLogo} width={200} />
        </Flex>
      );
    return <></>;
  };

  const renderPrompts = () => {
    if (showPrompts)
      return promptsList.map((prompt: any, idx: number) => (
        <div className="prompt-container" onClick={() => setInputVal(prompt.question)} key={idx}>
          {prompt.question}
        </div>
      ));
    return <></>;
  };

  const renderPromptsContainer = () => {
    if (promptsList.length !== 0 && isMultiDocQa(documentUuid))
      return (
        <Flex className="mt-auto mb-20" gap={20} style={{ position: "relative" }}>
          {showPrompts ? (
            <DownOutlined
              className="prompt-toggle"
              onClick={() => setShowPrompts(!showPrompts)}
              style={{ top: "-30px" }}
            />
          ) : (
            <UpOutlined
              className="prompt-toggle"
              style={{ top: "0" }}
              onClick={() => setShowPrompts(!showPrompts)}
            />
          )}
          {renderPrompts()}
        </Flex>
      );
    return <></>;
  };

  const renderQnAContainer = () => {
    if (queryResponseList.length > 0)
      return (
        <div className="msg-wrapper">
          <div className="msg-scroller">
            <div aria-label="chat box">
              {queryResponseList.map((obj) => {
                return (
                  <>
                    {obj.showQuery && (
                      <div className="msg msg-right">
                        <div
                          className="font-medium text-14 text-title"
                          style={{
                            border: "1px solid #F5F9FF",
                            backgroundColor: "#F7F7F9",
                            borderRadius: "3px",
                            padding: "13.5px 15px",
                            textAlign: "center",
                            lineHeight: "18px",
                          }}
                        >
                          {obj.query}
                        </div>
                      </div>
                    )}
                    {renderQueryAndResponse(obj.ans)}
                    <div className="flex gp-10 mt-20">
                      <div className="cursor-pointer flex">
                        <ThumbsUp />
                      </div>
                      <div className="cursor-pointer flex">
                        <ThumbsDown />
                      </div>
                      <Tooltip className="cursor-pointer flex">
                        <CopyOutlined style={{ fontSize: "18px", color: "#635F6A" }} />
                      </Tooltip>
                      <Tooltip className="cursor-pointer flex">
                        <RedoOutlined style={{ fontSize: "18px", color: "#635F6A" }} />
                      </Tooltip>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      );
    return <></>;
  };

  return (
    <>
      <Flex
        gap={15}
        className={`h-full bg-white provider-contract${
          showPrompts ? " provider-contract-prompts" : ""
        }`}
      >
        <Flex vertical className="msg-container">
          {renderLogoAndDescription()}
          {renderQnAContainer()}
          {renderPromptsContainer()}
          <Flex aria-label="chatbox textbox" gap={15} vertical className="mt-auto">
            <Search
              className="record-search"
              placeholder="Ask your question"
              enterButton={isProcessing ? <Loading /> : <SendOutlined />}
              onChange={(e) => setInputVal(e.target.value)}
              onSearch={handleQueryEvent}
              value={inputVal}
              disabled={isInputAndSearchDisabled}
            />
            <Flex gap={5} align="center" justify="center" style={{ fontSize: "12px" }}>
              Ask Auto can make mistakes, check important info.
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {showModal && (
        <SourceModal open={showModal} onClose={() => setShowModal(false)} source={selectedSource} />
      )}
    </>
  );
};
