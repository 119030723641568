export const PAGE_URL = {
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_SUCCESS: "/reset-password-success",
  CREATE_PASSWORD: "/create-password",
  CREATE_PASSWORD_SUCCESS: "/create-password-success",
  DASHBOARD: "/dashboard",
  PAYMENT_INTEGRITY: "/payment-integrity",
  DATASETS: "/datasets",
  SETTINGS: "/settings",
  PROJECTS: "/projects",
  REDIRECTION: "/redirect",
  PROVIDER_CONTRACTS: "/provider-contracts",
  PROVIDER_CONTRACTS_CONFIGURTION: "/provider-contracts-configuration",
  FILE_DETAILS: "file-details",
  ASK_AUTO: "/ask-auto",
  DOCUMENTS: "/documents",
  CREATE_PROJECTS: "/create-projects",
  PROVIDER_CONTRACT: "/provider-contract",
};

export const PAGE_TITLE = [
  { route: PAGE_URL.DASHBOARD, title: "Projects", displayBackButton: false },
  {
    route: "datasets",
    title: "Datasets",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.SETTINGS,
    title: "Topics Settings",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.REDIRECTION,
    title: "Authentication",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.PROVIDER_CONTRACT,
    title: "Provider Contract",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.FILE_DETAILS,
    title: "File details",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.CREATE_PROJECTS,
    title: "Create New Project",
    displayBackButton: false,
  },
];
