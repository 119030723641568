  import { Dispatch } from "redux";
  import { connect } from "react-redux";
  import DeleteConfirmation from "./DeleteConfirmation";
  import { closeModal, RootState, updateDeleteConfirmation } from "../../../store";
  import { MODAL_TYPE } from "../../../utils";

  type StateProps = {
    visibility: boolean;
    isDeleteConfirmed:boolean;
  };
  type DispatchProps = {
    handleClose: () => void;
    handleDelete: () => void;
    
  };
  export type DeleteConfirmationProps = StateProps & DispatchProps;

  const mapStateToProps = (state: RootState): StateProps => ({
    visibility: state.ui.modal[MODAL_TYPE.DELETE_CONFIRMATION],
    isDeleteConfirmed: state.ui.isDeleteConfirmed
  });

  const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    handleClose: () => {
      dispatch(closeModal(MODAL_TYPE.DELETE_CONFIRMATION));
    },
    handleDelete: () => {
      dispatch(updateDeleteConfirmation(true));
    },
  });

  export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirmation);
